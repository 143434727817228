.finance {
    background: #cea449;
    padding-top: 15px;
}

._finance-section-head4 {
    font-size: 22px;
    font-weight: 600;
    line-height: 25px;
    color: white;

}

._finance-app-section-title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 800px;
    margin: 0 auto;
}

._finace_section_p{
    border: 2px solid rgb(255, 255, 255);
    border-radius: 25px;
    padding: 10px 15px;
    margin: 10px 15px;
    font-weight: 700;
    color: #000;
    font-size: 16px;
}

._finance-section-a {
    margin-top: 30px;
    color: white;
}

.__welcome {
    font-size: 20px;
    line-height: 30px !important;
}

.invested-heading4 {
    font-size: 16px !important;
    line-height: 30px !important;
    color: grey;
}

.__btc {
    font-size: 16px !important;
    line-height: 30px !important;
    color: grey;
}

/* new css are here */
._financeBlogrow {
    padding-bottom: 15px;
    margin: 5px;
    background: #b0b3b2;
    padding-top: 10px;
    border-radius: 10px;
}

._financeBlogheader {
    margin: 15px;

}

._financeBlogheader h6 {
    font-size: 18px;
    font-weight: 600;
}



._finance-icon-image {
    width: 100px;
    height: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

._finance-icon-title {
    color: white;
    font-weight: 600;
    padding-top: 5px;
}

._finance-icon-main {
    justify-content: center;
    margin: 15px;
}

._finance-icon-head.col {
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    padding: 15px;
    margin: 5px;
}

._finance-icon.container {
    padding-bottom: 5px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

._finance-basic-titles>div:nth-child(1) {
    display: inline-flex;
    margin-bottom: 20px;
}

div._finance-basic-row:nth-child(2)>p:nth-child(2) {
    margin-left: 20px;
    font-size: 13px;
    font-weight: 501;
}

div._finance-basic-row:nth-child(3) {
    font-size: 30px;
    margin-top: 10px;
    font-weight: 800;
}

div._finance-Learn:nth-child(3)>div:nth-child(1)>div:nth-child(1)>div:nth-child(1)>div:nth-child(1)>div:nth-child(3) {
    padding-left: 15px;
}

._finance-blog-img {
    width: 50px;
    height: auto;
    border-radius: 50px;

}

.basics-titles {
    margin: 15px;
}

.basics-header {
    font-size: 18px;
    font-weight: 600;
    float: left;
    margin: 15px;
}



h5._finance-basic-head5 {
    margin-left: 15px;
    font-size: 16px;
    font-weight: 600;
}

div._finance-Learn:nth-child(4)>div:nth-child(1)>div:nth-child(1)>div:nth-child(1)>div:nth-child(1)>div:nth-child(3) {
    padding-left: 15px;
}

div._finance-Learn:nth-child(5)>div:nth-child(1)>div:nth-child(1)>div:nth-child(1)>div:nth-child(1)>div:nth-child(3) {
    padding-left: 15px;
}

div._finance-Learn:nth-child(8)>div:nth-child(1)>div:nth-child(1)>div:nth-child(1)>div:nth-child(1)>div:nth-child(3) {
    margin-left: 100px;
}

.right-angle1 {
    margin-left: 95px;
}

.right-angle3 {
    margin-left: 20px;
}

.right-angle2 {
    margin-left: 20px;
}

._finance-balance {
    background: rgb(255, 255, 255);
    ;
}

._finance-balance-section {
    margin: 10px;
}

._finance-balance-head {
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    background-color: rgb(206, 164, 73) !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

div._finance-balance-head1:nth-child(2)>h5:nth-child(1) {
    margin-top: 20px;
}

div._finance-balance-head1:nth-child(2)>h2:nth-child(2) {
    margin-bottom: 10px;
    margin-top: 0px;
}

.balance-heading1 {
    color: white;
    font-size: 17px;
    font-weight: 700;
}

.balance-heading2 {
    color: white;
    font-size: 25px;
    font-weight: 700;
    margin-top: 10px;
}

.balance-heading3 {
    color: white;
    font-size: 17px;
    font-weight: 700;
}

.balance-heading4 {
    color: white;
    font-size: 25px;
    font-weight: 700;
    margin-top: 10px;
}

div._finance-balance-head1:nth-child(1) {
    margin-left: 20px;
}

div._finance-balance-head1:nth-child(2) {
    margin-left: 20px;
    margin-top: 20px;
}

.caret-icon {
    float: right;
    margin-top: -20px;
    font-size: 15px;
    font-weight: bold;
    flex-basis: auto;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(5px);
    border-radius: 15px;
    font-family: 'Lato', sans-serif;
    line-height: 1.5;
    padding: 2px 7px 2px 7px;
    color: #0bad0b;
    background: white;
    opacity: 0.6;
}

.fa-caret-up.caret-ups1 {
    margin-right: 5px;
    color: #0bad0b;
}

.fa-plus.plus-1 {
    font-size: 10px;
    margin-right: 1px;
}

._finance-portfolio-head {
    padding-top: 1px;
    padding-bottom: 1px;
}

._finance-portfolio-item {
    display: flex;
}

._finance-portfolio-item2 {
    display: flex;
}

._finance-single-blog {
    background: #ffffff;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.btc-style {
    background: #ef8e19;
    color: white;
    font-size: 20px;
    padding: 10px;
    border-radius: 12px;
    margin-left: -15px;
    margin-top: -5px;
}

._finance-portfolio-heading {
    margin: 20px;
}

._finance-portfolio {
    background: #f6f6f6;
}

._finance-portfolio-main {
    font-size: 12px;
}

.invested-paragraph {
    margin-left: 75px;
    color: #079b07;
    font-weight: bold;
}

.investment-caret {
    margin-right: 5px;
}

.investment-plus {
    margin-right: 2px;
    font-size: 10px;
}

.reactapexchart-heading {
    margin-top: 15px;
    padding-right: 5px;
}

.__financeblogp {
    margin-left: 10px;
}

.bank.card {
    position: relative;
    display: flex;
    width: 395px;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #666;
    background-clip: border-box;
    border: 1px solid #23406f;
    border-radius: 7px;
    color: #eeee;
  }
  
  .bank .light {
    color: #cccccc;
    font-size: 11px;
  }
  .no-boxshadow{
    box-shadow: none!important;
  }

  .fix-loader {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    background-color: rgb(255 255 255 / 90%);
    display: flex;
    justify-content: center;
    align-items: center;
  }