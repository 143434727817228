.document-file {
    padding-top: 5px;
}

.btn.btn-green.ba-add-balance-btn.Main_link.document {
    font-size: 16px;
    font-weight: 600;
}

.btn.btn-red.ba-add-balance-btn.document {
    font-size: 16px;
    font-weight: 600;
}

.btn.btn-primary.document {
    height: 2rem;
    padding-top: 0;
    background-color: #B68D40;
}

.btn.btn-shadow.document {
    height: auto;
    padding-top: 0;
}

.document-close {
    background: none;
    box-shadow: none;
    color: rgba(0, 0, 0, .87);
    cursor: pointer;
    top: -2.5rem;
    right: -2.5rem;
    z-index: 1;
    opacity: .8;
    font-size: 20px;
    width: 50px;
    padding: .625rem 0 0 0;
    margin-top: -10px;
}

button.document-close:nth-child(1) {
    color: #122620;
}

#upload-file>div:nth-child(1)>div:nth-child(1)>div:nth-child(1)>div:nth-child(2)>button:nth-child(2) {
    color: #122620;
}

#create-member>div:nth-child(1)>div:nth-child(1)>div:nth-child(1)>h4:nth-child(1) {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 1.42857143rem;
    line-height: 1.28571429em;
    font-weight: 700;
}

.fa.fa-plus.document {
    padding-left: 10px;
}

.document-btn-files {
    background-image: none;
    text-shadow: none;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    margin-bottom: 15px;
    text-decoration: none;
    border-radius: .28571429rem;
    box-shadow: none;
    background-color: #2185d0;
    color: #fff;
    text-shadow: none;
    display: inline-block;
    padding: 7px;
}

.document-wife {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}

.fa.fa-folder.document-folder {
    color: #ffc928;
    margin-top: 3px;
}

.__document-wife {
    font-size: 10px;
    text-decoration: underline;
}

.link-document {
    margin-left: auto;
}
.link-document a{
    color: #122620;
    text-decoration: none;
    font-weight: 600;
}

.document-son {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
    color: black;
}

.__document-section {
    display: flex;
}

.document-bank {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
    color: black;
}

.document-li {
    padding-bottom: 15px;
}

li.document-name:nth-child(1) {
    display: flex;
    margin-bottom: 15px;
}

li.document-name:nth-child(2) {
    display: flex;
    margin-bottom: 15px;
}

li.document-name:nth-child(3) {
    display: flex;
    margin-bottom: 15px;
}

#document-wife>div:nth-child(1)>div:nth-child(1) {
    height: 260px;
}

#document-wife>div:nth-child(1)>div:nth-child(1)>div:nth-child(2)>div:nth-child(1)>div:nth-child(1)>h4:nth-child(1) {
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 15px;
}

#document-wife>div:nth-child(1)>div:nth-child(1)>div:nth-child(2)>div:nth-child(1)>div:nth-child(2)>div:nth-child(1)>label:nth-child(2) {
    padding-left: 15px;
    font-size: 15px;
    font-weight: 600;
}

#document-wife>div:nth-child(1)>div:nth-child(1)>div:nth-child(2)>div:nth-child(1)>div:nth-child(2)>div:nth-child(2)>label:nth-child(2) {
    padding-left: 15px;
    font-size: 15px;
    font-weight: 600;
}

#create-member>div:nth-child(1)>div:nth-child(1)>div:nth-child(2)>ul:nth-child(1)>li:nth-child(1)>label:nth-child(1) {
    font-size: 16px;
    font-weight: 600;
}

#create-member>div:nth-child(1)>div:nth-child(1)>div:nth-child(2)>ul:nth-child(1)>li:nth-child(2)>label:nth-child(1) {
    font-size: 16px;
    font-weight: 600;
}

#create-member>div:nth-child(1)>div:nth-child(1)>div:nth-child(2)>ul:nth-child(1)>li:nth-child(3)>label:nth-child(1) {
    font-size: 16px;
    font-weight: 600;
}

#fname {
    margin-left: 30px;
    font-size: 12px;
    width: 200px;
}

#create-member>div:nth-child(1)>div:nth-child(1)>div:nth-child(2)>li:nth-child(1)>input:nth-child(2) {
    margin: 0 0 .28571429rem 0;
    top: 0;
    margin-top: 30px;
    float: left;
    margin-left: -34px;
}

#create-member>div:nth-child(1)>div:nth-child(1)>div:nth-child(2)>li:nth-child(2)>input:nth-child(2) {
    margin: 0 0 .28571429rem 0;
    top: 0;
    margin-top: 30px;
    float: left;
    margin-left: -34px;
}

#create-member>div:nth-child(1)>div:nth-child(1)>div:nth-child(2)>li:nth-child(3)>input:nth-child(2) {
    margin: 0 0 .28571429rem 0;
    top: 0;
    margin-top: 30px;
    float: left;
    margin-left: -34px;
}

button.btn-primary:nth-child(1) {

    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    background-image: none;
    font-family: 'Lato', sans-serif;
    text-shadow: none;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: .28571429rem;
    box-shadow: none;
    background-color: #2185d0;
    color: #fff;
    text-shadow: none;
}

.btn.btn-primary.document.my-0 {
    padding: 7px !important;
}

/* .recent-files>h4:nth-child(1) {
    padding: 20px;
} */

.recent-files {
    height: auto !important;
}

#upload-files .modal-content,
#create-files .modal-content {
    box-shadow: 0px 0px 20px 2px #696969;
}

/* information  csss */

.information {
    margin: 0px;
}

.fa.fa-file-text-o.text-file {
    font-size: 18px;
    color: black;
    padding-left: 10px !important;
    margin: 0;
    padding: 0;
}

.fa-file-video-o.video-file {
    padding-left: 10px;
}

.fa-file-pdf-o.pdf-file {
    padding-left: 10px;
}

.fa-file-text.head-file {
    padding-left: 10px;
}

.fa-file-excel-o.excel-file {
    padding-left: 10px;
}

.fa-file-powerpoint-o.ppt-file {
    padding-left: 15px;
}

.fa-file-archive-o.zip-file {
    padding-left: 10px;
}

.pdf-inner {
    border: 1px solid #bbbb;
    margin: 0px;
    padding: 0px;
}

.pdf-active {
    display: flex;
    margin: 10px 0px 10px -25px;
}

.pdf-active img {
    box-shadow: none !important;

}

.pdf-inner:hover {
    background-color: rgb(182, 177, 177);
}


div.pdf-active:nth-child(1) {
    cursor: pointer;
}

div.pdf-active:nth-child(2) {
    cursor: pointer;
}

div.pdf-active:nth-child(3) {
    cursor: pointer;
}

._information-head {
    margin-top: 50px !important;
}

div._information-head:nth-child(2) {
    margin-bottom: 25px;
}

div._information-head:nth-child(3) {
    margin-bottom: 25px;
}

div._information-head:nth-child(4) {
    margin-bottom: 25px;
}

div._information-head:nth-child(5) {
    margin-bottom: 25px;
}

div._information-head:nth-child(6) {
    margin-bottom: 25px;
}

._information_head {
    margin: 10px 0px 10px 10px;
    line-height: 38px;
}

.video-content>img:nth-child(2) {
    margin-left: 20px;
}

.video-content>img:nth-child(3) {
    margin-left: 20px;
}

.video-content {
    justify-content: center;
}

._information>div:nth-child(1) {
    margin-bottom: 25px;
}

div._finance-Learn:nth-child(10)>div:nth-child(1)>div:nth-child(1)>div:nth-child(1)>div:nth-child(1)>div:nth-child(3)>i:nth-child(1) {
    margin-left: 20px;
}

._finance-app-section-head {
    background: #684be0;
}

._finance-app-section-title {
    display: flex;
    align-items: center;
    justify-content: center;
}

._finance-app-section-title1>h2:nth-child(1) {
    color: white;
}

._finance-app-section-title1>p:nth-child(2) {
    color: white;
}

._finance-app-section-title2 {
    margin-bottom: 7px;
}

.modal-title {
    margin-bottom: -15px;
    font-family: 'Playfair Display', serif;
}

.upload-file-section {
    position: relative;
    display: inline-block;
    width: 200px;
    padding-left: 1rem;
    padding-right: 1rem;
}

.upload-file-section-1 {
    margin-top: 15px;
    margin-left: 15px;
}

.upload-file-section-2 {
    position: relative;
    display: inline-block;
    width: 200px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 15px;
}

.upload-icon {
    margin-right: 10px;
}

.upload-file-section-paragraph {
    width: 120px;
    border: 1px solid;
    padding: 7px;
    border-radius: 15px;
}

.create-file-section {
    position: relative;
    display: inline-block;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 200px;
}

.create-file-section-2 {
    position: relative;
    display: inline-block;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 200px;
}

.create-file-section-1 {
    margin: 15px;
}




/* Select option checkbox css */

.multiselect {
    width: 200px;
}

.selectBox {
    position: relative;
}

.selectBox select {
    width: 100%;
    font-weight: bold;
}

.overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

#checkboxes {
    display: none;
    border: 1px #dadada solid;
}

#checkboxes label {
    display: block;
}

#checkboxes label:hover {
    background-color: #1e90ff;
}

.field.field-section {
    margin-top: 25px;
}

.upload__image-wrapper {
    margin-top: 15px;
    /* margin-left: 15px; */
}

.upload__image-wrapper-btn {
    padding: 10px;
    background: #B68D40;
    width: auto;
    color: white;
    text-align: left;
    padding: 5px 15px;
}

.upload__image-wrapper-btn1 {
    color: black;
    background: none;
    box-shadow: none;
    width: 30%;
    background: red;
    color: white;
    padding: 5px;
    margin-top: 10px;
    font-size: 12px;
}

/* vishal ss */

.table_Syle {
    border: 1px solid #bbbb;
    height: 40px;
    background-color: #5a32f1;
}

.td {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px 0px 10px 0px;

}

.userList {
    border-bottom: 1px solid #bbb;
    margin: 20px 0px 10px 0px;
}

.text-center_1 {
    border: 1px solid #bbb;
    text-align: center;
}

.css-bbq5bh {
    position: relative;
    display: inline-block;
    box-shadow: 0px 4px 5px -1px #000;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.tiny-text {
    font-size: 11px;
    color: #777;
}

.full {
    display: block;
    clear: both;
}

.folder-title {
    margin-left: 5px;
    line-height: 18px;
}

.dc-shimmer {
    display: flex;
    flex-direction: column;
}

.dc-shimmer-item {
    display: block;
    height: 30px;
    background-color: #f3f3f3;
    background: linear-gradient(to right, #f3f3f3 5%, #eee 20%, #f3f3f3 35%);
    animation: placeholderShimmer 2s linear 0s;
    margin: 5px 8px;
}

.hjgjh {
    overflow-x: auto;
    display: flex;
    padding: 10px;
}

.thumbeImage {
    margin-left: -13px;
    border: 0.125rem solid rgb(255, 255, 255);
    transition: .7s all ease-in-out;
    transform: scale(1);
    position: relative;
    object-fit: cover;
}

.thumbeImage:hover {
    margin-right: 13px;
    opacity: 1;
    z-index: 1000;
    transition: all 200ms ease-in-out 0s;
    cursor: pointer;
    transform: scale(1.4);
    /* transform: translateY(-4px); */
    z-index: 2;
}

.set_profile {
    height: 90px;
    position: relative;
    width: 90px;
    left: 34%;
    margin: 10px 0px 16px 0px;
}

.set_profile img {
    border-radius: 9999px;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 2;
}

@keyframes cycle-colors {
    0% {
        border-color: hsl(0, 100%, 50%);
    }

    25% {
        border-color: hsl(90, 100%, 50%);
    }

    50% {
        border-color: hsl(180, 100%, 50%);
    }

    75% {
        border-color: hsl(270, 100%, 50%);
    }

    100% {
        border-color: hsl(360, 100%, 50%);
    }
}

@keyframes pulse {
    to {
        opacity: 0;
        transform: scale(1);
    }
}

.set_profile::before,
.set_profile::after {
    animation: pulse 2s linear infinite;
    border: #1e1c1c solid 5px;
    border-radius: 9999px;
    box-sizing: border-box;
    content: ' ';
    height: 140%;
    left: -20%;
    opacity: .6;
    position: absolute;
    top: -20%;
    transform: scale(0.714);
    width: 140%;
    z-index: 1;
}

.set_profile::after {
    animation-delay: 1s;
}

/* .set_profile:hover img {
    content: url('http://s.blixt.org/hypnotoad.jpg');
} */

.set_profile:hover::before,
.set_profile:hover::after {
    animation: pulse 1s linear infinite, cycle-colors 6s linear infinite;
}

.set_profile:hover::after {
    animation-delay: .5s;
}

.modal.show {
    z-index: 1070;
}

.invalid-feedback {
    color: red;
    display: contents !important;
    font-size: 15px;
}

/* information css */


i.bi.bi-send.taskic:before {
    font-size: 20px;
    font-weight: bold !important;
}

.msg {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

.msg:last-of-type {
    margin: 0;
}

.msg-img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    background: #c10a0a;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
}

.msg-bubble {
    max-width: 450px;
    padding: 10px;
    border-radius: 15px;
    background: #ececec;
}

.msg-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.msg-info-name {
    margin-right: 10px;
    font-weight: bold;
}

.msg-info-time {
    font-size: 0.85em;
}

.left-msg .msg-bubble {
    border-bottom-left-radius: 0;
}

.right-msg {
    flex-direction: row-reverse;
}

.right-msg .msg-bubble {
    background-color: rgb(206, 164, 73);
    color: #fff;
    border-bottom-right-radius: 0;
    margin-right: 5px;
}

.right-msg .msg-img {
    margin: 0 0 0 10px;
}

.card-content_ {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .add-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .checklist-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .due-date {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
  
  .checklist-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .divider {
    border: 0;
    height: 1px;
    background: #e0e0e0;
  }
  
 /* Dialog Overlay */
.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999 !important; /* Highest priority */
  }
  
  /* Dialog Box */
  .dialog {
    background: #fff;
    border-radius: 8px;
    padding: 16px;
    width: 100%;
    max-width: 400px;
    position: relative;
    z-index: 999999 !important; /* Ensure it's on top */
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3); /* Enhanced visibility */
  }
  
  /* Dialog Header */
  .dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  /* Close Button */
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Dialog Content */
  .dialog-content {
    margin-bottom: 16px;
  }
  
  /* Input Field */
  .dialog-content input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Dialog Actions */
  .dialog-actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
  
  /* Cancel & Add Buttons */
  .cancel-button,
  .add-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .cancel-button {
    background: #ccc;
  }
  
  .add-button {
    background: #007bff;
    color: #fff;
  }
  