.columnthree{
    border: 1px solid #a7a5a5;
    margin: 0px;
    height: 240px;
    flex-wrap: wrap;
    padding: 0;
}
.nullimageclass{
   width: 70px;
   height: 70px;
   margin: 5px;
}

.padh3{
   margin-left: 10px;
}
.full-screen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loaderji {
  border: min(5px, 0.5vw) solid #f3f3f3;
  border-top: min(5px, 0.5vw) solid #3498db;
  border-radius: 50%;
  width: min(50px, 10vw);
  height: min(50px, 10vw);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .loaderji {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 480px) {
  .loaderji {
    width: 30px;
    height: 30px;
  }
}


.close-icd {
   position: absolute;
   right: 0px;
   background-color: white;
   color: black;
   border: 1px solid #ccc;
   border-radius: 50%;
   cursor: pointer;
   display: none;
 }

 .columnthree:hover .close-icd {
   display: block; 
 }

 .imgodvs{
   height: 90%;
   overflow-y: scroll;
   scrollbar-width: none;
 }

 .outfitname{
   cursor: pointer;
 }

 .pppp{
   font-weight: 500;
   border-top: 1px solid #a7a5a5;
   padding-left: 12px;
   font-size: 12px;
   margin: 0;
 
 }

 #outfitedit > .modal-content {
   background-color: rgb(244, 244, 232) !important;
   border-top-left-radius: 55px !important;
   border-bottom-right-radius: 55px !important;
   height: 430px;
   width: 450px;
 }
 .inptyuoutfit{
   border-radius: 5px !important;
   width: 100% !important;
   height: 27px !important;
   border: 1px solid grey !important;
   padding: 9px !important;
   font-size: 10px !important;
   background-color: white !important;
   color: black !important;
 }
 .txtyuoutfit{
   border-radius: 5px !important ;
   width: 100% !important;
   height: 220px !important;
   border: 1px solid grey !important;
   padding: 9px !important;
   font-size: 10px !important;
 }
 .line121out{
   margin-top: 20px;
   border-top: 1px solid #a3acb4;
   margin-bottom: 10px;
 }
 .gyuyg{
  margin-bottom: 33px;
 }
 .ugui{
  padding: 10px;
 }
 @media (max-width: 768px) {
   .columnthree {
     height: 120px;
   }
   .nullimageclass{
     width: 35px;
     height: 35px;
     margin: 2px;
  }
  .pppp{
     font-size: 8px !important;
     padding-left: 2px;
  }
  .columnthree:hover .close-icd {
     display: block; 
   }
}