   .addAssets{
    color: #fff;
    justify-content: center;
    }
    .juyuvuj{
      margin-top: 52px;
      margin-bottom: 45px;
    }
    .stockbtn{
      background-color: #fff;
      color: black;
      border: 1px solid grey;
      display: flex;
      height: fit-content;
    }
    .stockbtn:hover{
      background: #F8F9FA;
    }
    .addclickable:hover{
      color: #347EDD;
    }
    .arrowup{
      background: #E6F4EA;
      color: green;
      font-size: 15px;
      height: 38px ;
      width: 38px;
      border-radius: 8px;
      padding-top: 5px;
    }
    
    .arrowupicon{
      background: #E6F4EA;
      color: green;
      font-size: 15px;
      height: 38px;
      width: 38px;
      border-radius: 8px;
      padding: 10px;
    }
    .arrowdown{
      background: #FCE8E6;
      color: #C5221F;
      font-size: 15px;
      height: 38px;
      width: 38px;
      border-radius: 8px;
      padding-top: 5px;
    }
    .arrowdownicon{
      background: #FCE8E6;
      color: #C5221F;
      font-size: 15px;
      height: 38px;
      width: 38px;
      border-radius: 8px;
      padding: 10px;
    }
    .arrowfor{
      background: #F8F9FA;
      color: #000;
      font-size: 15px;
      height: 38px;
      width: 38px;
      border-radius: 8px;
      padding-top: 5px;
      padding: 10px;
    }
    .add{
      color: green;
      font-size: 15px;
    }
    .minus{
      color: #C5221F;
      font-size: 15px;
    }
    .MuiDataGrid-cellContent{
      font-size: 15px;
      color: #212121;
    }
    .MuiDataGrid-columnHeaderTitle{
    font-size: large;
    color: #212121;
    }
    .titlee{
      font-size: 12px;
      font-weight: bold;
      padding-left: 5px;
      color: #000;
    }
    .price{
      font-size: 12px;
      margin-left: 10px;
    }
    
    #bigrect,#smallrect,#vsmallrect,#vbigrect{
        height: 120px;
        border: 3px solid #347EDD;
        background-color: #E9E9E9;
        margin-top: 15px;
        cursor: pointer;
        width: 120px;
      }
    
      .hadding p{
        font-size: 14px;
        margin-top: 10px;
        margin-left: 10px;
        font-weight: bold;
        min-height: 65px;
    
      }
      .hadding span{
        font-size: 12px;
        margin-left: 10px;
      }
    
      .googlehead p{
        font-size: 12px;
        margin-left: 10px;
        margin-top: 45px;
        margin-right: 10px;
    
      }
      .googlehead span{
        font-size: 14px;
        margin-left: 10px;
        font-weight: bold;
      }
    
      .brkhead p{
        font-size: 12px;
        margin-left: 10px;
        margin-top: 30px;
        margin-right: 10px;
    
      }
      .brkhead span{
        font-size: 14px;
        margin-left: 10px;
        font-weight: bold;
        margin-top: 10px !important;
      }
    
      .MuiTab-textColorPrimary.Mui-selected{
        font-weight: 600 !important;
    }
    
    #customized-dialog-titlee{
        font-weight: bold;
    }
    .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop{
        background: none !important;
    }
    .css-14ndc4c-MuiPaper-root{
        -webkit-transition: none;
        transition: none;
        border-radius: none;
        box-shadow: none;
        padding: none;
        border: 1px solid #a19191;
    }
    .MuiPaper-rounded{
        border-radius: none;
    }
    .index{
      background: none;
      color: #000;
      border: 1px solid;
      margin-right: 5px;
    }
    .newslink{
      font-family: "Google Sans",Roboto,Arial,sans-serif;
        line-height: 1.5rem;
        font-size: 20px;
        letter-spacing: .00625em;
        font-weight: 500;
        color: rgb(32,33,36);
        padding-top: 8px;
        text-decoration: none;
    }
    .W8knGc {
      font-family: Roboto,Arial,sans-serif;
      font-weight: 400;
      color: rgb(95,99,104);
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
      margin-left: 25px;
      width: 100%;
    }
    .Adak{
      padding-left: 10px;
    }
    .Adak:before {
      content:"• ";
    }
    .hideRightSeparator_1 {
      background-color: #F09E30 ;
      border: 1px solid #5D6D7E;
    }
    .hideRightSeparator_2 {
      border: 1px solid #5D6D7E;
      background-color: #4DD8D5 
    }
    .hideRightSeparator_3 {
      border: 1px solid #5D6D7E;
      background-color: #42D86D ;
    }
    .hideRightSeparator_4{
      border: 1px solid #5D6D7E;
      background-color: #85929E ;
    }
    .hideRightSeparator_5{
      background-color: #42D86D ;
      border: 1px solid #5D6D7E;
    }
    ._listdata{
      display: block;
    }
    .selected {
      display: none; 
    }
    
    .offmodal {
      overflow-y: auto;
      position: fixed;
      top: 9%;
      right: 5%;
      background-color: white;
      border: 1px solid #ccc;
      padding: 20px;
      z-index: 10000;
      width: 89%;
      height: 100%;
    }
    
      

    /* ///cbhbcj custom */
    .juyguygou{
      margin: 10px;
    }
    .custom-tabs {
      width: 100%;
    }
    
    .tab-headers {
      text-align: center;
      width: 75%;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
    }
    
    .tab-header {
      padding: 10px 20px;
      cursor: pointer;
      font-weight: bold;
      color: #333;
    }
    
    .tab-header.active {
      border-bottom: 3px solid black;
    }
    
    .tab-contentitb {
      padding: 20px;
    }
    
  
.addAssetss{
  background-color: #347EDD;
  padding: 5px;
  width: fit-content;
  padding: 11px 20px 12px 20px;
  border-radius: 3px;
  color: white;
}

.huhih{
  float: right;
  margin-right: 110px;
}
.huhiuhiul{
  border-radius: 10px;
  height: 80px;
  width: 120px;
}

@media (max-width: 768px) {

  .tab-headers {
    flex-wrap: wrap;
    justify-content: center;
  }

  .tab-header {
    display: flex;
    justify-content: center;
    padding: 0;
    width: 20%;
    font-size: 8px;
  }
  .tab-header.active{
    border-bottom: 1px solid black;
  }
  .addAssetss{
    padding: 6px 9px 6px 9px;
    font-size: 8px;
  }
  .huhih{
    margin-right: 30px;
  }
  .arrowup {
    background: #E6F4EA;
    color: green;
    font-size: 12px;
    height: 24px !important;
    width: 24px !important;
    border-radius: 0px;
    padding-top: 3px;
    }
    .arrowdown{
      background: #FCE8E6;
      color: #C5221F;
      font-size: 12px;
      height: 24px !important;
      width: 24px !important;
      border-radius: 0px;
      padding-top: 3px;
    }
    .titlee{
      font-size: 7px;
    } 
    .price{
      font-size: 7px;
    }
    .newslink{
        line-height: 0px !important;
        font-size: 7px;
        font-weight: 500;
        color: rgb(32, 33, 36);
        padding-top: 0px;
        text-decoration: none
    }
    .news-source{
      font-size: 9px;
    }
    .news-date{
      font-size: 9px;
    }
    .huhiuhiul{
      border-radius: 5px;
      height: 67px;
      width: 74px;
    }
    .news-content{
      line-height: 9px;
    }
    .tab-contentitb {
      padding: 20px 0px 0px 0px;
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .arrowup {
    background: #E6F4EA;
    color: green;
    font-size: 15px;
    height: 28px ;
    width: 28px;
    border-radius: 6px;
    padding-top: 3px;
    }
    .arrowdown{
      background: #FCE8E6;
      color: #C5221F;
      font-size: 15px;
      height: 28px;
      width: 28px;
      border-radius: 6px;
      padding-top: 3px;
     }
    .price{
      font-size: 9px;
      margin-left: 6px;
    }
    .titlee{
      font-size: 9px;
    }
    .news-content{
      line-height: 5px;
    }
    .huhih{
      margin-right: 25px;
    }
  

}


.stock-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.autocomplete-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.divider {
  height: 28px;
  width: 1px;
  background-color: #ccc;
  margin: 0 10px;
}

.stock-card {
  border: 2px solid gray;
  padding: 13px;
  margin-top: 10px;
  background: #e9e9e9;
}

.stock-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  justify-content: space-between;
}

.stock-symbol {
  font-size: 20px;
  font-weight: 700;
  margin-right: 10px;
}

.stock-name {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.close-btn {
  position: absolute;
  /* right: 0; */
  top: 50%;
  transform: translateY(-50%);
}

.stock-input {
  border: 2px solid gray;
  border-top: none;
  width: 100%;
  height: 50px;
  padding: 13px;
  font-weight: 600;
}

.total-value {
  margin-top: 10px;
}

.stock-list {
  height: 70vh;
  overflow-y: auto;
  scrollbar-width: none;
}

.stock-item {
  padding: 10px;
  border: 2px solid gray;
  background: #e9e9e9;
  margin-bottom: 10px;
  cursor: pointer;
}

.stock-item.selected {
  background-color: #ccc;
}

.add-more-btn {
  margin-top: 20px;
  font-weight: 700;
  font-size: 12px;
  text-decoration: underline;
}

.done-btn {
  margin-top: 20px;
  padding: 10px 48px;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  background: #000;
  border-radius: none;
}

.bhvuo{
  cursor: pointer;
  margin-top: 10px;
  margin-left: 2px;
  font-weight: 700;
  font-size: 12px;
  text-decoration: underline;
  color: #347EDD;
}
/* style={{marginTop: 10, padding: 10, paddingRight: 48, paddingLeft: 48, color: "#fff", fontWeight: 700, fontSize: 12, background: '#000', borderRadius: "none" }} */

.guugul{
   margin-top: 10px;
   color: #fff;
   font-weight: 700;
   font-size: 10px;
   width: 26%;
   background-color: black;
   border-radius: none;
   border-radius: 3px;
}
