/**
 * Call CSS
 */



.Outer-solidBg-44 {
  background-color: #2c2c2c !important;
}

#mainContent{
    padding-right: 0!important;
}

body.webcall {
  background-color: #2c2c2c;
}

.video-chat-container {
    display: flex;
    width: 100%;
    background-color: #2c2c2c;
}
.videos {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  height: 100vh;
  padding-bottom: 55px;
}

.chat {
  width: 450px;
  display: none;
}

.video-bg .user-avatar img {
  width: 90p;
  height: 90px;
  object-fit: cover;
}

.video-bg .user-avatar {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
}
.video-bg {
  position: absolute;
}

.bottom-panel {
    background-color: rgba(65, 65, 65, 0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 1;
}

.userName {
  font-size: 12px;
  position: absolute;
  bottom: 2px;
  left: 2px;
  background: rgba(0, 0, 0, .75);
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: last baseline;
  z-index: 999;
}

.userName span.material-icons {
  width: 14px;
  height: 14px;
  font-size: 13px;
  display: inline-flex;
  margin-left: 5px;
  position: relative;
  top: 2px;
}

.width0 {
  width: 0% !important;
}
.width-peer0 {
  width: 50% !important;
}
.width-peer1 {
  width: 49% !important;
  height: calc(100vh - 10%);
}

.width-peer2 {
  width: 49% !important;
  height: calc(100vh - 60%);
}
.width-peer3 {
  width: 49% !important;
  height: calc(100vh - 60%);
}
.width-peer4 {
  width: 28% !important;
  height: calc(100vh - 60%);
}
.width-peer5 {
  width: 28% !important;
  height: calc(100vh - 60%);
}
.width-peer6 {
  width: 25% !important;
}
.width-peer7 {
  width: 25% !important;
}
.width-peer8 {
  width: 25% !important;
}
.width-peer {
  width: 20% !important;
}

@media(max-width: 640px) {
    .width-peer1 {
      width: 100%!important;
    }

    .width-peer1:last-child {
      height: 100px;
      position: fixed;
      bottom: 100px;
      right: 10px;
      width: 130px !important;
      z-index: 99999;
      background-color: #5c5c5c;
      border-radius: 4px;
    }

}

.alertbox {
  position: fixed;
  z-index: 999999;
  padding: 4px;
  transform: translate(-50%, -50%);
  margin-left: 50%;
  margin-right: 50%;
  min-width: 320px;
  top: 50%;
  background: #eee;
}
.videobox {
  max-width: 320px;
  margin: auto;
}
.videobox video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.videos video {
  object-fit: cover;
}
.meetingchoice {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.choicebtn-group {
  padding-left: 10px;
  display: flex;
}
.choicebtn-group .custom-switch {
  margin: 5px;
}

.tooltip-container {
  border-radius: 50%;
  position: relative;
  margin: 5px;
  justify-content: center;
  display: flex;
}

.tooltip-container .btn {
  width: 48px;
  height: 48px;
  padding: 0;
  line-height: normal;
}

.tooltip-container .tooltip {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: none;
}

button.btn.btn-disabled {
  background: #5c5c5c;
}

button.btn.btn-enabled {
  color: #ffffff !important;
}