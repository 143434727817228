.huhhohhi{
    width: 10% !important;
    font-size: 14px;
}

@media (max-width: 768px) {
    .kjbbiubiu{
        overflow-x: scroll;
        scrollbar-width: none;
    }
    .theadct{
        font-size: 9px;
    }
    .tbodyct{
        font-size: 8px;
    }
}
.knnoioi{
    font-size: 18px;
    font-weight: 700;
}
.StockSpan{
    color: red;
    font-size: 13px;
    margin-right: 15px;
}
.container_price{
    font-size: 20px;
    font-weight: 700;
}
.bjbibl{
    justify-content: space-between;
}