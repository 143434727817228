.video-call-container {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.video-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 12px;
    padding: 20px;
    flex-wrap: wrap;
}

.video {
    flex: 1 1 calc(100% - 50px);
    height: 100%;
    max-width: 100%;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0px 0px 8px -4px #000;
}

.video > video {
    width: 100%;
}

.video-call-action {
    position: fixed;
    bottom: 10px;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-call-action > .center-widget {
    padding: 10px 20px;
    background-color: #222222;
    border-radius: 40px;
    display: flex;
    gap: 10px;
    max-height: 80px;
}

.video-call-container .size-1 .video.local {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1;
    width: 320px;
    height: 180px;
}

.video-call-container .size-1 .video.remote {
    width: 100%;
    height: 100%;
}

.video-call-container .size-2 .video, .video-call-container .size-3 .video {
    width: 50%;
    height: 50%;
}