.tab-containerrr {
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 48px;
}
.full-screen-loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
  }
  
  .loaderji {
	border: min(5px, 0.5vw) solid #f3f3f3;
	border-top: min(5px, 0.5vw) solid #3498db;
	border-radius: 50%;
	width: min(50px, 10vw);
	height: min(50px, 10vw);
	animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }
  
  @media (max-width: 768px) {
	.loaderji {
	  width: 40px;
	  height: 40px;
	}
  }
  
  @media (max-width: 480px) {
	.loaderji {
	  width: 30px;
	  height: 30px;
	}
  }

.tab-boxxx {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ccc;
  display: flex;
  height: 2.5rem;
}

.tab-btnnn {
  background-color: #f1f1f1;
  border: none;
  padding: 5px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 50%;
}

.tab-btnnn:hover {
  background-color: #ddd;
}

.tab-btnnn.active {
  color: white !important;
  background-color: rgb(3, 62, 3) !important;
}

.tab-contenttt {
  display: none;
  /* padding: 20px; */
  height: 100%;
  margin-bottom: 20px;
}

.tab-contenttt.active {
  display: block;
}

/* CHMfeatured */

.collie {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 95px;
  margin-bottom: 10px;
  background-color: white;
}

.featured-image {
  object-fit: cover;
  width: 100%;
  /* Set the width to fill the container */
  height: 93px;
  /* Set the height to fill the container */
}

.col-md-2 {
  padding: 0;
}

.namee1 {
  margin-top: 6px;
  font-size: 14px;
  color: black;
}

.namee2 {
  font-size: 13px;
  font-style: italic;
  color: black;
}

.namee3 {
  font-size: 13px;
  font-weight: 600px;
  margin-top: 20px;
  color: black;
}

.col {
  margin-left: 15px;
}

/* noteic */

/* .eat1{
 background-image: url("https://img.freepik.com/free-photo/venetian-mask-eyes-black-fabric_23-2147595734.jpg?t=st=1709273701~exp=1709277301~hmac=e91ce7afb050063ebe4039a1cc04ebce770d6ba29018d6abd2da321606fa77ab&w=996");
color: white;
height: 310px;
width: 50%;
text-align: center;
background-size: cover;
}

.eat2{
 background-image: url("https://img.freepik.com/free-photo/venetian-masks-black-red-fabric_23-2147595724.jpg?t=st=1709273989~exp=1709277589~hmac=6cac4f48946c953075bdcb96c718a7c5f0d7f3a063da37ffcf7b08e12a1d4342&w=996");
color: white;
height: 310px;
width: 50%;
text-align: center;
background-size: cover;

}

.eatimg{
  object-fit: cover;
  width: 100%; 
  height: 40%; 
}
.imageText {
  position: absolute;
  
  color: white; 
  font-size: 18px;
  font-weight: bold;
  transform: translate(-50%, -50%);
    left: 50%;
    top: 2%;
}
.sty{
  margin-top: 30px;
  font-size: 15px;
}
.qui{
  font-size: 20px;
    margin-top: -12px;
}
.put{
  margin-top: 40px;
  font-style: italic;
}
.your{
  margin-top: -14px;
    font-style: italic;
}
.disc{
  font-size: 14px;
    margin-top: 30px;
    font-weight: 600;
}
.dixc{
  font-size: 14px;
    font-weight: 600;
    margin-top: -11px;
}
.discx{
  font-size: 14px;
    margin-top: 52px;
    font-weight: 600;
}
.secd{
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
.eat3{
  background-image: url("https://img.freepik.com/free-photo/fashionable-men-winter-jacket-with-zipper-close-up-generated-by-ai_188544-43270.jpg?t=st=1709272498~exp=1709276098~hmac=f5c8bbf265461629eefce57185f0dfaaebffb820a4f1223468286a7cbde9af9e&w=1060");
 color: white;
 height: 310px;
 width: 100%;
 text-align: center;
 background-size: cover;
 }
 .thirdt{
  font-size: 15px;
  margin-top: 22px;
  margin-bottom: 22px;
 }
 .sectext{
  font-size: 15px;
 }
 .spn{
  font-size: 16px;
  font-style: italic;
 }
 .fordt{
  font-size: 14px;
  margin-bottom: 24px;
 }
 .fivdt{
  font-size: 9px;
  margin-top: 15px;
  margin-bottom: 15px;
 }
 .eat4{
  background-image: url("https://img.freepik.com/free-photo/multi-colored-garments-hanging-fashionable-clothing-store-generative-ai_188544-53806.jpg?t=st=1709275726~exp=1709279326~hmac=17a92cce2b6ee81a77b1453d2f0afbaafb5babadc24e14bd51af909a69cd1853&w=1060");
 color: white;
 height: 310px;
 width: 100%;
 text-align: center;
 background-size: cover;
 }
 .eat5{
  background-image: url("https://c7.alamy.com/comp/2RNRT06/old-navy-store-in-times-square-features-young-womens-clothing-new-york-city-usa-2023-2RNRT06.jpg");
 color: white;
 height: 310px;
 width: 100%;
 text-align: center;
 background-size: cover;
 } */

.eat1,
.eat2 {
  background-size: cover;
  color: white;
  height: 310px;
  width: 50%;
  text-align: center;
}

.eat1 {
  background-image: url("https://img.freepik.com/free-photo/venetian-mask-eyes-black-fabric_23-2147595757.jpg?t=st=1716366643~exp=1716370243~hmac=fa156424e0e012106b198d069d02f223352054d4b528757699a51c9458361cae&w=996");
}

.eat2 {
  background-image: url("https://img.freepik.com/free-photo/venetian-masks-black-red-fabric_23-2147595724.jpg?t=st=1716366685~exp=1716370285~hmac=32b128bbe4db48feaccc972bf78fd1d82cebc3b14d124a1c8dbb471fe78d3614&w=996");
}

.eat3,
.eat4,
.eat5 {
  background-size: cover;
  color: white;
  height: 310px;
  width: 100%;
  text-align: center;
}

.eat3 {
  background-image: url("https://img.freepik.com/free-photo/fashionable-men-winter-jacket-with-zipper-close-up-generated-by-ai_188544-43270.jpg?t=st=1709272498~exp=1709276098~hmac=f5c8bbf265461629eefce57185f0dfaaebffb820a4f1223468286a7cbde9af9e&w=1060");
}

.eat4 {
  background-image: url("https://img.freepik.com/free-photo/multi-colored-garments-hanging-fashionable-clothing-store-generative-ai_188544-53806.jpg?t=st=1709275726~exp=1709279326~hmac=17a92cce2b6ee81a77b1453d2f0afbaafb5babadc24e14bd51af909a69cd1853&w=1060");
}

.eat5 {
  background-image: url("https://c7.alamy.com/comp/2RNRT06/old-navy-store-in-times-square-features-young-womens-clothing-new-york-city-usa-2023-2RNRT06.jpg");
}

.imageText {
  position: absolute;
  color: white;
  font-size: 18px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 2%;
}

.sty {
  margin-top: 30px !important;
  font-size: 15px;
  color: white !important;
}

.qui {
  font-size: 20px;
  margin-top: -12px;
  color: white !important;
}

.put,
.your,
.disc,
.dixc,
.discx {
  font-style: italic;
  color: white !important;
}

.put {
  margin-top: 40px;
}

.your {
  margin-top: -14px;
}

.disc,
.dixc {
  font-size: 14px;
  font-weight: 600;
}

.disc {
  margin-top: 30px;
}

.dixc {
  margin-top: -11px;
}

.discx {
  font-size: 14px;
  margin-top: 52px;
  font-weight: 600;
}

.secd {
  text-align: center;
  margin: 50px 0;
}

.thirdt,
.sectext,
.fordt,
.fivdt {
  font-size: 14px;
  color: black;
}

.thirdt {
  margin: 22px 0;
}

.fordt {
  margin-bottom: 24px;
}

.fivdt {
  font-size: 9px;
  margin: 15px 0;
}

@media (max-width: 768px) {

  .eat1,
  .eat2 {
    width: 100%;
  }

  .secd,
  .sectext {
    font-size: 12px;
    color: black;
  }

  .thirdt,
  .fordt,
  .fivdt {
    font-size: 12px;
  }

  .sty,
  .qui,
  .put,
  .your,
  .disc,
  .dixc,
  .discx {
    font-size: 12px;
    color: white !important;
  }
}

/* 
 ///// item4*/
.fordttt {
  font-size: 14px;
  margin-bottom: 28px;
  margin-left: 13%;
  margin-right: 13%;
  color: black;
  text-align: center;
  /* Center text */
}

.eat11,
.eat22,
.eat12,
.eat23 {
  color: white;
  height: 420px;
  width: 50%;
  text-align: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.styu {
  margin-top: 35px;
  font-size: 15px;
  color: white !important;
}

.quiop {
  margin-top: -14px;
  font-size: 18px;
  color: white !important;
}

.putop {
  font-size: 22px;
  margin-top: 98px;
  font-style: oblique;
  color: white !important;
}

.yourop {
  margin-top: -19px;
  font-size: 18px;
  font-style: oblique;
  color: white !important;
}

.putopp {
  margin-top: 90px;
  font-size: 14px;
  color: white !important;
}

.youropu {
  font-size: 14px;
  margin-top: -12px;
  color: white !important;
}

.putopi {
  margin-top: 122px;
  font-size: 22px;
  font-style: oblique;
  color: white !important;
}

.putoppiop {
  margin-top: 104px;
  font-size: 14px;
  color: white !important;
}

@media (max-width: 768px) {
  .fordttt {
    margin-left: 5%;
    margin-right: 5%;
    font-size: 12px;
  }

  .eat11,
  .eat22,
  .eat12,
  .eat23 {
    width: 100%;
    height: auto;
    /* Adjust height for better responsiveness */
    margin-bottom: 20px;
    /* Add spacing between sections */
  }

  .putop,
  .putopi,
  .putopp,
  .putoppiop {
    margin-top: 20px;
  }

  .putop,
  .putopi {
    font-size: 18px;
  }
}

/* //// */
.item5div1 {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  height: 180px;
  display: flex;
  align-items: center;
}

.item5div2 {
  width: 160px;
  margin-left: 3px;

  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #e5e5e5;
  cursor: pointer;
  border: 1px solid black;
  height: 150px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.item5div3 {
  width: 80%;
  margin-left: 20px;
}

.divvv4 {
  color: black;
  font-size: 20px;
  margin-top: -17px;
}

.divvv5 {
  color: black;
  font-style: italic;
  font-size: 12px;
}

.sttyy {
  margin-top: -16px;
}

.divvv6 {
  display: flex;
  margin-top: 65px;
  text-align: center;
  justify-content: center;
  width: 18%;
  color: white;
  border: 1px solid;
  background-color: rgb(2, 48, 2);
  height: 32px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.div77 {
  font-size: 13px;
  margin-top: 6px;
}

.lio {
  margin-top: 20px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.hirow {
  height: 62px;
  display: flex;
}

.twocol {
  margin-right: 30px;
  display: flex;
  align-items: center;
  font-size: 36px;
  margin-left: 12px;
}

.tendiv {
  width: 90%;
}

.pone {
  margin-top: 14px !important;
  font-size: 14px;
}

.ptwo {
  font-size: 13px;
  margin-top: -14px;
}

.lastitem5 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item5las {
  margin-bottom: 50px;
  margin-top: 15px;
  font-size: 12px;
  color: #bdbdbd;
  font-weight: 600;
}

@media (max-width: 768px) {
  .twocol {
    font-size: 30px;
  }

  .divvv6 {
    display: flex;
    margin-top: 40px;
    text-align: center;
    justify-content: center;
    width: 65%;
    color: white;
    border: 1px solid;
    background-color: rgb(2, 48, 2);
    height: 32px;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

/* 
collection */

.dibba1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0d310d;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  margin-top: 30px;
  margin-left: 20px;
  height: 90px;
  width: 100px;
}

.texttttti1 {
  text-align: center;
  color: white;
  font-size: 13px;
  font-style: italic;
}

.dibba2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(220, 180, 220);
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  margin-top: 30px;
  margin-left: 20px;
  height: 90px;
  width: 100px;
}

.texttttti2 {
  text-align: center;
  color: black;
  font-size: 13px;
  font-style: italic;
}

.dibba3 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d2b48c;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  margin-top: 30px;
  margin-left: 20px;
  height: 90px;
  width: 100px;
}

.dibba {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  margin-top: 30px;
  margin-left: 20px;
  height: 90px;
  width: 100px;
}

.texttttti3 {
  text-align: center;
  color: black;
  font-size: 13px;
  font-style: italic;
}

.dibba4 {
  background: #e9f0f5;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  margin-top: 30px;
  margin-left: 20px;
  height: 90px;
  width: 100px;
}

.texttttti4 {
  margin-top: -10px;
  text-align: center;
  color: black;
  font-size: 11px;
}

.texttttti {
  color: black;
  font-size: 11px;
  font-style: italic;
}

.pluss {
  font-size: 30px;
  text-align: center;
  margin-top: 15px;
}

.selecteddt {
  border: 2px solid black;
}

@media (max-width: 768px) {
  .texttttti {
    font-size: 8px;
  }
  .dibba {
    border-bottom-right-radius: 14px;
    border-top-left-radius: 14px;
    margin-top: 20px;
    margin-left: 13px;
    height: 45px;
    width: 48px;
  }
  .texttttti4{
    font-size: 7px;
  }
  .texttttti3{
    font-size: 7px;
  }
  .texttttti2{
    font-size: 7px;
  }
  .texttttti1{
    font-size: 7px;
  }
  .dibba4 {
    border-bottom-right-radius: 14px;
    border-top-left-radius: 14px;
    margin-top: 20px;
    margin-left: 13px;
    height: 45px;
    width: 48px;
  }
  .pluss{
    margin-top: 6px;
    margin-bottom: 1px;
    font-size: 16px ;
  }
  .coll1{
    font-size: 10px;
  }
  .coll2{
    font-size: 5px;
  }
  
}

.coll1 {
  color: black;
  font-size: 14px;
  margin-top: 60px;
}

.coll2 {
  color: black;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.coll3 {
  color: black;
  font-size: 14px;
  margin-top: 20px;
}

.helh2 {
  margin-top: 28px;
  margin-bottom: 6px;
  font-size: 12px;
  font-family: 'Times New Roman', Times, serif !important;
}

.llpllp{
  margin-left: 8px;
   font-size: 18px !important;
}
.inph2 {
  border-radius: 5px;
  width: 100%;
  height: 30px;
  border: 1px solid grey;
  padding-left: 8px;
  /* margin-top: 5px; */
}

.footer_bnt_coll {
  background-color: rgb(7, 48, 21);
  color: white;
  border: 1px solid black;
  font-weight: 500;
  font-size: 12px;
  padding: 3px 14px;
  display: flex;
  justify-content: space-between;
  width: 260px;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: auto;
  cursor: pointer;
}

.footer_bnt_disabled {
  filter: opacity(50%);
  background-color: rgb(7, 48, 21);
  color: white;
  border: 1px solid black;
  font-weight: 500;
  font-size: 12px;
  padding: 3px 14px;
  display: flex;
  justify-content: space-between;
  width: 260px;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: auto;
  cursor: not-allowed;
}

.oo1boxx {
  background: #013701;
  height: 27px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  width: 28px;
}

.oo2boxx {
  background: rgb(220, 180, 220);
  height: 27px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  width: 28px;
  margin-left: 10px;
}

.oo3boxx {
  background: rgb(206, 206, 104);
  height: 27px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  width: 28px;
  margin-left: 10px;
}

.oo4boxx {
  background: #444913;
  height: 27px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  width: 28px;
  margin-left: 10px;
}

.oo5boxx {
  background: rgb(118, 113, 113);
  height: 27px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  width: 28px;
  margin-left: 10px;
}

.oo6boxx {
  background: red;
  height: 27px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  width: 28px;
  margin-left: 10px;
}

.oo7boxx {
  background: white;
  height: 27px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  width: 28px;
  margin-left: 10px;
}

.line121 {
  border-top: 1px solid #dee2e6;
  margin-bottom: 8px;
}

.selected-color {
  border: 2px solid black;
}

.selected-boxIE {
  border: 2px solid black;
}

.lnhug76f {
  height: 80px;
  width: 90px;
  margin-right: 20px;
}

.njbiuiu {
  border-top: 1px solid rgb(150, 145, 145);
  border-bottom: 1px solid rgb(150, 145, 145);
  height: 94px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.kjiii {
  color: black;
}

.hgy {
  color: black;
  font-size: 14px;
}

.calh6776 {
  font-size: 18px;
}

.tab_containerTabS {
  margin-bottom: 10px;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.jkkl {
  min-height: 410px;
  max-height: 410px;
  justify-content: start;
  overflow: hidden auto;
  scrollbar-width: none;
}

.calh6776CAL {
  margin-right: 204px;
  
}

.gyf67f {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  background: white;
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid;
}

.selectedborder {
  border: 2px solid;
}

.bnj {
  margin-bottom: 10px;
}

.color_listing {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.oplkoplk {
  padding: 2px 20px 0 20px;
  font-size: 11px;
  text-align: center;
}

.lkoplkop {
  text-align: center;
}

.whitieboixxcoll {
  margin: 20px 15px;
  background-color: white;
  height: 420px;
  border: 1px solid;
}

.nullimageclassColl {
  width: 35px;
  height: 35px;
  margin: 5px;
}

.columnthreeColl {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  height: 135px;
  flex-wrap: wrap;
}

#mnbvcxxcxoiop > .modal-content {
	border-top-left-radius: 50px !important;
	border-bottom-right-radius: 50px !important;
	background-color: rgb(244, 244, 232) !important;
	border-top-right-radius: 0px;
	border-bottom-left-radius: 0px;
}

.foldericonhower {
  color: black;
  position: absolute;
  top: -10px;
  right: -10px; 
  opacity: 0;
  transition: opacity 0.3s ease;
  background: white;
  border-radius: 50%;
  border: 1px solid #ccc; 
  cursor: pointer;
}

.dibba:hover .foldericonhower {
  opacity: 1;
}
.subfolder-container {
  position: relative; 
  cursor: pointer;
}

.subfoldericonhower {
  position: absolute;
  top: -8px; 
  right: 5px; 
  display: none; 
  cursor: pointer; 
  background: white;
  border-radius: 50%;
  border: 1px solid #ccc; 
}

.subfolder-container:hover .subfoldericonhower {
  display: block; 
}

#mnbvcxxcio>.modal-content {
  border-top-left-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  background: rgb(244, 244, 232) !important;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 550px;
  border: none !important;
  backdrop-filter: blur(0px) !important;
  box-shadow: none !important;

}