.tab-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 40px;
  padding: 0;
  /* background-color: #fcfaed; */
}

.tab-box {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ccc;
  display: flex;
  height: auto;
}

.tab-btn {
  color: black;
  background-color: #fcfaed;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex: 1 1 33.5%;
  text-align: center;
}

.tab-btn:hover {
  background-color: #ddd;
}

.tab-btn.active {
  background-color: #ddd;
}

.tab-contenti {
  display: none;
  min-height: 80vh;
}

.tab-contenti.active {
  display: block;
}

#onetwo.active {
  background: hsla(39, 20%, 72%, 1) !important;
  color: #000000 !important;
}
#onetwo{
  border: 1px solid #979090;
  font-weight: 600;
  font-size: 16px;
}

@media (max-width: 768px) {

  .tab-btn {
    flex: 1 1 100%;
  }
}
@media (max-width: 480px) {
  .datepicdimns{
    width: 25px;
  }
  #onetwo{
    font-size: 15px;
  }
  
}