.external_desc {
    display: flex;
}

.external_bank {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 0px;
    background: rgba(243, 244, 246);
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.TbwUpd.ojE3Fb {
    display: flex;
    padding: 0;
    align-items: center;
    padding-bottom: 0;
}

.H9lube {
    background-color: #f1f3f4;
    border: 1px solid #ecedef;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    margin-right: 12px;
}

.XNo5Ab.XNo5Ab {
    display: block;
    box-shadow: none !important;
    height: 22px;
    width: 22px;
}

.VuuXrf {
    color: #202124;
    font-size: 15px;
    max-width: 200px;
    display: block;
    line-height: 20px;
    white-space: nowrap;
    font-weight: bold;
    margin-bottom: 0;
}

.external_account {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.external_data {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.external_datap {
    font-weight: 600;
    color: lightslategray;
}

.external_datap1 {
    font-weight: 500;
    color: lightslategray;
}

.external_mail {
    width: 100%;
    content: 'Select some files';
    display: inline-block;
    background: linear-gradient(to left top, #f9f9f9, #e3e3e3);
    border: 2px solid #1135ff;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
}

.external_eauth {
    font-size: 10px;
    font-weight: 700;
    padding-top: 5px;
    color: lightslategray;
}

._auth_rize {
    font-size: 10px;
    font-weight: 600;
    color: lightslategray;
    padding-top: 20px;
}

.back_button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: none !important;
    border: 2px solid #1135ff;
    border-radius: 3px;
    padding: 0;
    margin: 0;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 700;
    font-size: 10pt;
    height: 30px;
}


.confirm_button {
    display: inline-flex;
    background-color: #1135ff !important;
    color: #fff;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 2px solid #1135ff;
    border-radius: 3px;
    padding: 0;
    margin: 0;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 500;
    font-size: 10pt;
    height: 30px;
}

.pd-top-10{
    padding-top: 10px;
}