.select_itms{
    padding: 5px;

}
.select_name{
    font-size: 14px;
    font-weight: 600;

}
.clear_n{
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
}
.selectitem_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.selectitem_header{
    border-bottom: 1px solid;
}

.category_type{
    padding: 4px;
    margin: 10px 10px 5px 0px;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: center;
    width: 100%;
    cursor: pointer;
}
.category_type.selecteddi {
    background-color: rgb(0, 0, 0);
    color: #fff;
}
.color_type_{
    height: 30px;
    width: 30px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin: 10px 10px 5px 5px;
    cursor: pointer;
}
.color_items{
    overflow-x: auto;
    scrollbar-width: none;
}
.select_item_list{
    height: 40vh;
    overflow-y: auto;
    scrollbar-width: none;
    margin-top: 10px;
}
.color_listhw{
    height: 20px;
    width: 20px;
    margin-top: 10px;
    cursor: pointer;
}
.selecteddi123{
    border: 1px solid black;
}