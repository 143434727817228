@media screen and (min-width: 480px) {
    .gallery-image {
        width: 300px;
        height: 200px;
        object-fit: cover;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.22);
        border-radius: 3px;
    }
}

@media only screen and (max-width: 600px) {
    .gallery-image {
        width: 150px !important;
        height: 100px;
        object-fit: cover;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.22);
        border-radius: 3px;
    }
}

/* display: block;
  width: 200px;
  height: 143px;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.25), 0 2px 2px rgba(0,0,0,0.22);
  object-fit: cover; */
/* Dark Mode */