.rendeir {
    display: flex;
    justify-content: end;
}

.filter_int {
    margin-left: 30px;
    width: 28px;
    height: 26px;
    margin-right: 20px;
    margin-top: 10px;
    cursor: pointer;
    margin-bottom: 13px;
}

.flier_pnt {
    margin-top: 10px;
}

.evnic {
    margin-right: 20px;
    margin-top: 10px;
    cursor: pointer;
    margin-bottom: 13px;
    margin-left: 20px;
}
.fool_tb {
    margin-left: 20px;
    margin-right: 20px;
}