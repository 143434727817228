.margiii {
  margin-bottom: 50px;
}
.full-screen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loaderji {
  border: min(5px, 0.5vw) solid #f3f3f3;
  border-top: min(5px, 0.5vw) solid #3498db;
  border-radius: 50%;
  width: min(50px, 10vw);
  height: min(50px, 10vw);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .loaderji {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 480px) {
  .loaderji {
    width: 30px;
    height: 30px;
  }
}


.lopsi {
  width: 100%;
}



.maindivie {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.takei{
  padding: 0px;
  margin: 0px;
  border: 1px solid #979090;
}
.chdiv {
  height: 282px;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
  /* margin-top: 17px; */
  background-color: white;
}

.imgdiv {
  height: 91%;
  width: 100%;
  position: relative;
  overflow: hidden;
  /* padding: 10px 10px 1px 10px; */
}

.imgdiv img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.imgdiv:hover img {
  filter: brightness(50%);
}

.textdiv p {
  margin: 0;
}

.pp {
  font-weight: 600;
  margin: 0;
  padding-left: 10px;
  border-top: 1px solid #979090;
  font-size: medium;
}

.lul {
  border: 1px solid;
  width: 35%;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: -7px;
  font-weight: 700;
}

.lull {
  display: flex;
  justify-content: center;
}

.middle {
  display: flex;
  flex-wrap: wrap;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 54%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.imgdiv:hover .middle {
  opacity: 1;
}

.texttt {
  padding-top: 10px;
  color: black;
  font-size: 8px;
  width: 100%;
}

.buttonnn {
  border: 1px solid black;
  margin: 5px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: white;
  height: 55px;
  width: 52px;
  cursor: pointer;
}

.filter_i {
  width: 30px;
  height: 30px;
  float: right;
  margin-right: 20px;
  margin-top: 10px;
  cursor: pointer;
}

.flier_p {
  width: 100%;
}

@-webkit-keyframes come-in {
  0% {
    -webkit-transform: translatey(100px);
    transform: translatey(100px);
    opacity: 0;
  }

  30% {
    -webkit-transform: translateX(-50px) scale(0.4);
    transform: translateX(-50px) scale(0.4);
  }

  70% {
    -webkit-transform: translateX(0px) scale(1.2);
    transform: translateX(0px) scale(1.2);
  }

  100% {
    -webkit-transform: translatey(0px) scale(1);
    transform: translatey(0px) scale(1);
    opacity: 1;
  }
}

@keyframes come-in {
  0% {
    -webkit-transform: translatey(100px);
    transform: translatey(100px);
    opacity: 0;
  }

  30% {
    -webkit-transform: translateX(-50px) scale(0.4);
    transform: translateX(-50px) scale(0.4);
  }

  70% {
    -webkit-transform: translateX(0px) scale(1.2);
    transform: translateX(0px) scale(1.2);
  }

  100% {
    -webkit-transform: translatey(0px) scale(1);
    transform: translatey(0px) scale(1);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .chdiv {
    height: 220px;
  }

  .imgdiv {
    padding: 5px;
  }

  .buttonnn {
    height: 45px;
    width: 40px;
  }

  .middle {
    left: 43%;
  }

  .texttt {
    font-size: 6px;
    padding-top: 4px;
  }

  .buttonnn {
    height: 40px;
  }

}



.floating-container {
  position: absolute;
  width: 36px;
  height: 330px;
  bottom: 0%;
  right: 3%;
  /* margin: 35px 25px; */
}


/* .floating-container:hover {
      height: 300px;
    } */
/* .floating-container:hover .floating-button {
      box-shadow: 0 10px 25px rgba(231, 236, 160, 0.6);
      -webkit-transform: translatey(5px);
              transform: translatey(5px);
      -webkit-transition: all 0.3s; 
      transition: all 0.3s;
    } */
.floating-container .element-container .float-element:nth-child(1) {
  -webkit-animation: come-in 0.4s forwards 0.2s;
  animation: come-in 0.4s forwards 0.2s;
}

.floating-container .element-container .float-element:nth-child(2) {
  -webkit-animation: come-in 0.4s forwards 0.2s;
  animation: come-in 0.4s forwards 0.2s;
}

.floating-container .element-container .float-element:nth-child(3) {
  -webkit-animation: come-in 0.6s forwards 0.2s;
  animation: come-in 0.6s forwards 0.2s;
}

.floating-container .element-container .float-element:nth-child(4) {
  -webkit-animation: come-in 0.8s forwards 0.2s;
  animation: come-in 0.8s forwards 0.2s;
}

.floating-container .element-container .float-element:nth-child(5) {
  -webkit-animation: come-in 1s forwards 0.2s;
  animation: come-in 1s forwards 0.2s;
}

.floating-container .floating-button {
  position: absolute;
  width: 40px;
  height: 40px;
  background: rgb(236, 236, 185);
  bottom: 0;
  left: 0;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  right: 0;
  margin: auto;
  color: black;
  line-height: 35px;
  text-align: center;
  font-size: 23px;
  z-index: 100;
  font-weight: 100;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.floating-container .float-element {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-weight: 100;
  cursor: pointer;
  margin: 15px auto;
  color: black;
  font-weight: 500;
  text-align: center;
  line-height: 35px;
  z-index: 0;
  opacity: 0;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  background-color: rgb(236, 236, 185);
}

.floating-container .float-element .material-ic {
  vertical-align: middle;
  font-size: 16px;
}


.bodystyle {
  border-top-left-radius: 55px;
  border-bottom-right-radius: 55px;
  background-color: rgb(244, 244, 232);
  overflow: hidden;
}

.item1 {
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  margin-top: 20px;
  color: black;
}

.item2 {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
  color: black;
}

.item22 {
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  border: 1px solid;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  left: 50%;
}

#idy>.modal-content {
  border-top-left-radius: 55px !important;
  border-bottom-right-radius: 55px !important;
  height: 288px;
  width: 450px;
}

.footer_bntt_f {
  border: 1px solid;
  font-weight: 700;
  font-size: 12px;
  /* background-color: rgb(1, 40, 1); */
  padding: 2px 14px;
  display: flex;
  justify-content: space-between;
  width: 38%;
  /* color: white; */
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: auto;
  cursor: pointer;
}

.footer_bnt_ff {
  border: 1px solid;
  font-weight: 500;
  font-size: 12px;
  background-color: rgb(8, 51, 8);
  padding: 3px 14px;
  display: flex;
  justify-content: space-between;
  width: 38%;
  color: rgb(244, 244, 232);
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: auto;
  cursor: pointer;
}

.marg {
  margin-bottom: 12px;
}

.margi {
  margin-bottom: 45px;
}

.button_name_foot {
  color: black;
  font-size: 12px;
  font-weight: 500;
}

#qwertyu>.modal-content {
  border-top-left-radius: 45px !important;
  border-bottom-right-radius: 45px !important;
  background-color: rgb(244, 244, 232) !important;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

#iddlyy>.modal-content {
  border-top-left-radius: 55px !important;
  border-bottom-right-radius: 55px !important;
  height: 500px;
  width: 500px;
}


#tabunmod>.modal-content {

  background-color: rgb(244, 244, 232) !important;


}

#qwertyuo>.modal-content {
  background-color: rgb(244, 244, 232) !important;
  border-top-left-radius: 55px !important;
  border-bottom-right-radius: 55px !important;
  height: 425px;
  width: 500px;
  max-width: 90%;
  max-height: 90%;
}

#mnbvc>.modal-content {
  background-color: rgb(244, 244, 232) !important;
  border-top-left-radius: 55px !important;
  border-bottom-right-radius: 55px !important;
  height: 350px;
  width: 450px;
}

#lkjh>.modal-content {
  background-color: rgb(244, 244, 232) !important;
  border-top-left-radius: 55px !important;
  border-bottom-right-radius: 55px !important;
  height: 460px;
  width: 500px;
}

#lkiopl>.modal-content {
  margin-left: 58px;
  background-color: rgb(244, 244, 232) !important;
  border-top-left-radius: 55px !important;
  border-bottom-right-radius: 55px !important;
  height: 300px;
  width: 380px;
}

.cname1 {
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
}

.cname11 {
  background: #023102;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 96px;
  height: 32px;
  border-top-left-radius: 11px;
  border-bottom-right-radius: 11px;
}

.cname13 {
  color: white;
  font-weight: 500;
  font-size: 12px
}

.cname12 {
  margin: auto;
  font-size: 13px;
  font-weight: 500
}

.cname2 {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 15px;
}

.loliilo {
  color: #464545;
  font-size: 13px;
  margin-top: 5px;
}

.inptyu {
  border-radius: 5px;
  width: 250px;
  height: 27px;
  border: 1px solid grey;
  padding: 9px;
  font-size: 10px;
}
@media (max-width: 768px) {
  #qwertyuo>.modal-content {
    height: 75%;
    width: 90%;
    border-top-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
  }
  
  .cname11 {
    width: 80px;
    height: 28px;
  }

  .cname12 {
    font-size: 12px;
  }

  .cname13 {
    font-size: 11px;
  }

  .loliilo {
    font-size: 12px;
  }

  .inptyu {
    max-width: 100%;
    font-size: 9px;
  }

}

@media (max-width: 480px) {
  #qwertyuo>.modal-content {
    height: 60%;
    width: 95%;
    border-top-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }
  
  .cname11 {
    width: 70px;
    height: 24px;
  }

  .cname12 {
    font-size: 11px;
  }

  .cname13 {
    font-size: 10px;
  }

  .loliilo {
    font-size: 11px;
  }

  .inptyu {
    max-width: 60%;
    font-size: 8px;
  }
}

.tipopo {
  margin-top: 3rem;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
}

.tipopo1 {
  margin-top: 3rem;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
}

.tipopo2 {
  margin-top: 1px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
}

.tickiop {
  margin-top: 6rem;
}

.footer_flioop {
  border: 1px solid;
  font-weight: 500;
  font-size: 12px;
  background-color: rgb(8, 51, 8);
  padding: 3px 14px;
  display: flex;
  justify-content: space-between;
  width: 160px;
  color: white;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: auto;
  cursor: pointer;
}

.footer_dltop {
  border: 1px solid;
  font-weight: 500;
  font-size: 12px;
  background-color: rgb(8, 51, 8);
  padding: 5px 14px;
  display: flex;
  justify-content: space-between;
  width: 160px;
  color: white;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: auto;
  cursor: pointer;
}

.footer_dltop1 {
  border: 1px solid;
  font-weight: 500;
  font-size: 12px;
  background-color: transparent;
  padding: 3px 14px;
  display: flex;
  justify-content: space-between;
  width: 160px;
  color: black;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: auto;
  cursor: pointer;
}

.dltop {
  margin-top: 4rem;
}

.dltop1 {
  margin-top: 1rem;
}

.diviie234 {
  text-align: center;
  margin-top: 3rem;
  font-size: 13px;
  font-weight: 500;
}

.liopll {
  margin-bottom: 0px;
}

.zxcvfds {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10rem;
}

.confirmationnn-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 9999;
}

.confirmationnn-dialog p {
  margin-bottom: 20px;
}

.confirmationnn-dialog button {
  margin-right: 10px;
}

#confirmationnn-box {
  display: none;
  position: fixed;
  top: 50%;
  left: 46%;
  transform: translate(-50%, -50%);
  z-index: 9999999;
  margin-left: 31px;
  background-color: rgb(244, 244, 232) !important;
  border-top-left-radius: 55px !important;
  border-bottom-right-radius: 55px !important;
  height: 300px;
  width: 380px;
}

#confirmationnn-box p {
  margin: 0 0 10px 0;
}

#confirmationnn-box button {
  margin-right: 10px;
}


.dynamicpage-img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.dynamicpage-container {
  text-align: center;
  /* Center the image container */
}

@media (max-width: 768px) {
  .dynamicpage-img {
    width: 80%;
  }
  #confirmationnn-box {
    left: 42%;
  }
}