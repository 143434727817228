.header_outfit{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid;
    padding: 5px;
}
.out_fit{
    padding: 5px;
    
}
.name_ou{
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}
.body_outfit{
   padding: 5px; 
}
.collection_space{
    height: 50vh;
}
.line_{
    height: 1px;
    width: 100%;
    background-color: black;
}
.button_create{
    background-color: rgb(3, 79, 3);
    padding: 8px;
    border-top-left-radius:10px;
    border-bottom-right-radius:10px;
    width: 50px;
    text-align: center;
    margin: auto;
    margin-top: -20px;
    cursor: pointer;
    
}
.footer_outfit{
    padding: 10px;
    border-top: 1px solid;
    width: 100%;
}
.footer_bnt_f{
    background-color: rgb(6, 67, 6);
    padding: 3px 10px;
    display: flex;
    justify-content: space-between;
    width: 40%;
    align-items: center;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: auto;
    cursor: pointer;
}
#lmkl > .modal-content {
    background-color: rgb(244, 244, 232) !important;
}
.button_name_f{
    color: white;
}