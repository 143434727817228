#tabpanelli .MuiBox-root {
	padding: 0px !important;
}

#homes_tabpanel .MuiBox-root {
	padding: 2px !important;
}
#askjdhsjhdkj > .modal-content {
	border-top-left-radius: 40px !important;
	border-bottom-right-radius: 40px !important;
	background-color: rgb(244, 244, 232) !important;
	border-top-right-radius: 0px;
	border-bottom-left-radius: 0px;
}
#idyy > .modal-content {
	height: 610px;
	border-top-left-radius: 72px  !important;
	border-bottom-right-radius: 40px !important;
	background-color: rgb(244, 244, 232) !important;
	border-top-right-radius: 0px;
	border-bottom-left-radius: 0px;
}
.bodystyleie {
	 /* border-top-left-radius: 55px; */
	border-bottom-right-radius: 40px; 
	background-color: rgb(244, 244, 232);
	overflow: hidden;
	backdrop-filter: blur(0px) !important;
}
.full-screen-loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
  }
  
  .loaderji {
	border: min(5px, 0.5vw) solid #f3f3f3;
	border-top: min(5px, 0.5vw) solid #3498db;
	border-radius: 50%;
	width: min(50px, 10vw);
	height: min(50px, 10vw);
	animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }
  
  @media (max-width: 768px) {
	.loaderji {
	  width: 40px;
	  height: 40px;
	}
  }
  
  @media (max-width: 480px) {
	.loaderji {
	  width: 30px;
	  height: 30px;
	}
  }
  

.button_name_fnext {
	color: black;
	font-size: 12px;
	font-weight: 500;
}
.footer_bnt_collie {
	background-color: rgb(219 219 215);
	color: white;
	border: 1px solid black;
	font-weight: 500;
	font-size: 12px;
	padding: 3px 14px;
	display: flex;
	justify-content: space-between;
	width: 260px;
	align-items: center;
	border-top-left-radius: 10px;
	border-bottom-right-radius: 10px;
	margin: auto;
	cursor: pointer;
}
.helh2hqQ {
	font-size: 18px !important;
	font-family: none;
	font-weight: 400;
	font-family: sans-serifm !important;
	margin: 0 !important;
}
.helh2hq {
	font-size: 17px !important;
	font-family: none;
	font-weight: 400;
	font-family: sans-serifm !important;
}
.cvbn {
	font-size: 13px;
	margin-top: 10px;
	font-weight: 400;
	font-family: sans-serif !important;
}
.cvbnm {
	font-size: 13px;
	margin-top: 7px;
	font-weight: 400;
	font-family: sans-serif !important;
}
.whitieboix {
	margin: 20px 15px;
	background-color: white;
	height: 350px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.whitieboixxc {
	margin: 20px 15px;
	background-color: white;
	height: 350px;
}
.alboix {
	font-size: 13px;
	font-weight: 500;
}

#idyqwey > .modal-content {
	border-top-left-radius: 40px !important;
	border-bottom-right-radius: 40px !important;
	background-color: rgb(244, 244, 232) !important;
	border-top-right-radius: 0px;
	border-bottom-left-radius: 0px;
}

.helh2asa {
	font-size: 13px;
	margin-top: 20px;
	margin-bottom: 6px;
}
.inphdfg2 {
	border-radius: 5px;
	width: 100%;
	height: 27px;
	border: 1px solid grey;
	padding-left: 8px;
}

.hitieboixie {
	margin: 20px 15px;
	background-color: white;
	height: 360px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.lboixie {
	font-size: 13px;
	font-weight: 500;
}
.pack1 {
	border: 1px solid black;
	width: 96px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom-right-radius: 15px;
	border-top-left-radius: 15px;
}

.pack2 {
	border: 1px solid black;
	width: 96px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom-right-radius: 15px;
	border-top-left-radius: 15px;
	margin-left: 15px;
}
.lit {
	font-size: 12px;
	font-weight: 600;
}
.cimg {
	font-size: 13px;
	margin-top: 20px;
	margin-bottom: 6px;
}
#loipll > .modal-content {
	background-color: rgb(244, 244, 232) !important;
	border-top-left-radius: 55px !important;
	border-bottom-right-radius: 55px !important;
	height: 630px;
	width: 500px;
}
.liopll {
	margin-bottom: 0px;
	margin-left: 140px;
}
.boiiboix {
	border: 1px solid black;
	border-top-left-radius: 35px;
	border-bottom-right-radius: 35px;
}
.htwoing {
	font-size: 17px !important;
	margin-top: 35px !important;
	margin-left: 20px !important;
	font-family: sans-serif !important;
	font-weight: 400;
}
.inplkj {
	margin-left: 18px;
	border-radius: 5px;
	width: 90%;
	height: 27px;
	border: 1px solid grey;
	margin-bottom: 15px;
	padding: 8px;
	margin-top: 20px;
}
.footer_collie {
	margin-left: 16%;
	background-color: rgb(219 219 215);
	color: white;
	border: 1px solid black;
	font-weight: 500;
	font-size: 12px;
	padding: 5px 14px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top-left-radius: 10px;
	border-bottom-right-radius: 10px;
	margin-bottom: 30px;
	margin-top: 30px;
	width: 65%;
	cursor: pointer;
}
.button_fnext {
	color: black;
	font-size: 12px;
	font-weight: 500;
}
.selecteddt {
	border: 2px solid black;
}

.oo1box {
	margin-left: 20px;
	background: #013701;
	height: 27px;
	border-bottom-right-radius: 6px;
	border-top-left-radius: 6px;
	width: 28px;
}
.oo2box {
	background: rgb(220, 180, 220);
	height: 27px;
	border-bottom-right-radius: 6px;
	border-top-left-radius: 6px;
	width: 28px;
	margin-left: 10px;
}
.oo3box {
	background: rgb(206, 206, 104);
	height: 27px;
	border-bottom-right-radius: 6px;
	border-top-left-radius: 6px;
	width: 28px;
	margin-left: 10px;
}
.oo4box {
	background: #444913;
	height: 27px;
	border-bottom-right-radius: 6px;
	border-top-left-radius: 6px;
	width: 28px;
	margin-left: 10px;
}
.oo5box {
	background: rgb(118, 113, 113);
	height: 27px;
	border-bottom-right-radius: 6px;
	border-top-left-radius: 6px;
	width: 28px;
	margin-left: 10px;
}
.oo6box {
	background: red;
	height: 27px;
	border-bottom-right-radius: 6px;
	border-top-left-radius: 6px;
	width: 28px;
	margin-left: 10px;
}
.oo7box {
	background: white;
	height: 27px;
	border-bottom-right-radius: 6px;
	border-top-left-radius: 6px;
	width: 28px;
	margin-left: 10px;
}
.mnbvc {
	margin-left: 20px;
	margin-top: -12px;
	margin-bottom: 17px;
}
.oo1boxie {
	background: rgb(237, 191, 237);
	height: 27px;
	border-bottom-right-radius: 6px;
	border-top-left-radius: 6px;
	width: 100%;
	border: 1px solid #8b8b8b;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
}
.oo1boxiees {
	background: rgb(247 175 247);
	height: 27px;
	border-bottom-right-radius: 6px;
	border-top-left-radius: 6px;
	border: 1px solid #8b8b8b;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
}

.oo2boxie {
	background: rgb(237, 191, 237);
	height: 27px;
	border-bottom-right-radius: 6px;
	border-top-left-radius: 6px;
	width: 130px;
	margin-left: 40px;
	border: 1px solid #8b8b8b;
	margin-bottom: 20px;
}
.liopl {
	display: flex;
	justify-content: center;
	height: 100%;
	align-items: center;
	font-size: 12px;
	font-weight: 600;
}

.oo1boxie-container {
	display: flex;
	flex-wrap: wrap;
}
.whitieboixxcyion {
	margin: 20px 15px;
	background-color: white;
	height: 400px;
}
.footer-bottomm {
	position: fixed;
	width: 100%;
	z-index: 9;
	bottom: 0;
	left: 0;
	margin-bottom: -2px;
}

.bottom-nav {
	border-top: 1px solid black;
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #fcfaed;
	height: auto;
	z-index: 1000000;
}

.nav-item-custom {
	width: 100%;
	display: flex;
	justify-content: center;
}
.bottom-nav li.nav-item {
	width: 20% !important;
}
@media (max-width: 768px) {
	.bottom-nav {
		padding-right: 0px;
		max-width: 100% !important;
		margin: 0 !important;
	}
}

.dfghjkjhgfghjkjhgc{
  margin: 0;
  padding: 10px;
  border: none !important;
  border-top-left-radius: 40px;
  background-color: rgb(244, 244, 232) !important ;
  box-shadow:  none !important;
  backdrop-filter:  blur(0px) !important;
}

