.investment{
    display: flex;
    margin-bottom: 18px;
}
.investment h1{
    font-size: 35px;
    font-weight: 700;
}
.investment_heading{
    display: flex;
    font-size: small;
    line-height: 0.5;
    padding: 6px;
    padding-left: 20px;
    font-weight: 500;
}
.investment_con{
    margin-left: 10px;
}
.investment_amount{
    font-size: 20px;
    font-weight: 600;
    padding: 10px;
}
.active-class{
    display: none;
}

@media (max-width: 768px) {
    .investment h1{
        font-size: 17px;
    }
    .investment_heading{
        padding: 0px;
        margin-left: 9px;
    }
    .jhuj{
        font-size: 9px;
        margin-bottom: 5px;
    }
 
    .investment_amount{
        padding: 0px;
        font-size: 14px;
    }
    .stock-name{
       font-size: 7px;
    }
    .stock-symbol{
        font-size: 9px;
    }
    .uguiguigui{
        padding: 3px !important;
        font-size: 8px;
    }
  }

  