.stock-details {
    display: flex;
    max-width: 100%;
  }
  
  .stock-info {
    padding: 15px;
    border-right: 1px solid #000;
  }
  
  .stock-info:last-child {
    border-right: none;
  }
  
 
  .stock-info-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
    padding: 5px 0;
  }
  
  .label {
    font-size: 0.875rem;
    font-weight: 400;
    color: #000;
  }
  
  .value {
    font-size: 0.875rem;
    font-weight: 600;
    text-align: end;
    color: #000;
  }
    