.custom_tab22 {
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
}

.tab_header22 {
    display: flex;
}

.tab_item22 {
    font-size: 11px;
    font-weight: 600;
    flex: 1;
    padding: 9px 20px;
    cursor: pointer;
    text-align: center;
    background-color: #f0f0f0;
    border-right: 1px solid #ccc;
    transition: background-color 0.3s;
}

.tab_item22:last-child {
    border-right: none;
}

.tab_item22:hover {
    background-color: #e0e0e0;
}

.tab_item22.active {
    background-color: rgb(237, 191, 237);
}

.tab_content22 {
    padding: 20px;
}

.tab_block22 {
    display: block;
}


.inphmn{
    font-size: 12px;
    border-radius: 5px;
    width: 100%;
    height: 27px;
    border: 1px solid grey;
    margin-bottom: 15px;
    padding: 5px;
}
.lolii{
    color: #464545;
    margin-bottom: 5px;
    font-size: 13px;
}
.clecteddt {
    border: 2px solid black;
  
  }

  .dibbi1{
    background: black;
    height: 27px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    width: 28px;
  }
  .dibbi2{
    background: rgb(220, 180, 220);
    height: 27px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    width: 28px;
    margin-left: 23px;
  }
  .dibbi3{
    background: #555d0f;
    height: 27px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    width: 28px;
    margin-left: 23px;
  }
  .dibbi4{
    background:blue ;
    height: 27px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    width: 28px;
    margin-left: 23px;
  }
  .dibbi4{
    background: rgb(132, 3, 132);
    height: 27px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    width: 28px;
    margin-left: 23px;
  }
  .dibbi5{
    background: rgb(234, 148, 162);
    height: 27px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    width: 28px;
    margin-left: 23px;
  }
  .dibbi6{
    background: orangered;
    height: 27px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    width: 28px;
    margin-left: 23px;
  }
  .dibbi7{
    background: green;
    height: 27px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    width: 28px;
    margin-left: 23px;
  }
  .dibbi8{
    background: yellow;
    height: 27px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    width: 28px;
    margin-left: 23px;

  }
  .dibbi9{
    background: red;
    height: 27px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    width: 28px;
    margin-left: 23px;
  }
  .dibbi10{
    background: grey;
    height: 27px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    width: 28px;
  }
  .dibbi11{
    background: #FFFDD0;
    height: 27px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    width: 28px;
    margin-left: 23px;
  }
  .dibbi12{
    background: white;
    height: 27px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    width: 28px;
    margin-left: 23px;
  }
  .dibbi13{
    background: rgb(155, 152, 152);
    height: 27px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    width: 28px;
    margin-left: 23px;
  }
  .dibbi14{
    background: #757e26;
    height: 27px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    width: 28px;
    margin-left: 23px;
  }
  .bo_r1{
    border: 2px solid #ccc;
    width: 210px;
    height: 25px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .te_xt{
    height: 100%;
    font-size: 11px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bo_r2{
    margin-left: 22px;
    border: 2px solid #ccc;
    width: 210px;
    height: 25px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .inphmin{
    border-radius: 5px;
    width: 100%;
    height: 90px;
    border: 1px solid grey;
    margin-bottom: 15px;
    padding: 8px;
  }
  .inphmin::placeholder{
    top: 10px;
    position: absolute;
  }
  #loke > .modal-content {
    border-top-left-radius: 55px !important;
    border-bottom-right-radius: 55px !important;
    height: 380px;
    width: 400px;
    /* margin-left: 46px; */
    background-color: rgb(244, 244, 232);

}
.footer_flio{
  border: 1px solid;
  font-weight: 500;
  font-size: 12px;
  background-color: rgb(8, 51, 8);
  padding: 3px 14px;
  display: flex;
  justify-content: space-between;
  width: 160px;
  color: white;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: auto;
  cursor: pointer;
}
.tipo{
    margin-top: 8rem;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
}
.ticki{
  margin-top: 8rem;
}



.color_items{
justify-content: start;
}

.color_listhw{
  height: 20px;
  width: 20px;
  margin-top: 10px;
  cursor: pointer;
}
.lippdr{
 position: relative;
 width: 100%;
 margin-bottom: 15px;
}
.itemsdrp{
  border-radius: 5px;
  width: 100%;
  border: none;
  padding: 8px;
  background-color: transparent;
}
.inptexcv{
  border-radius: 5px;
  width: 100%;
  height: 27px;
  border: 1px solid grey;
  padding: 8px;
}
.bacgroundcolowrrwrwwr{
  border: 1px solid black;
}