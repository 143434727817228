
.zoom {
    margin-top: 50px;
}

.zoom_header{
    height: 100vh;
    overflow-y: scroll;
}

.zoommainr {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 0px;
    background: rgba(243, 244, 246);
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.avtarcol {
    display: flex;
    align-items: center;
}

.avatar-group {
    display: inline-flex;
}

.tooltip-wrapper {
    display: inline-flex;
}

.avatar-group-chained>.tooltip-wrapper {
    margin-left: -0.5rem;
}

.cursor-pointer {
    cursor: pointer;
    width: 30px;
    height: 30px;
    min-width: 30px;
    line-height: 30px;
    font-size: 12px;
}

.avatar-circle {
    border-radius: 9999px;
    display: block;
    height: 30px;
    object-fit: cover;
    width: 100%;
}

.avatar {
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
    color: rgba(243, 244, 246, var(--tw-text-opacity));
    display: inline-block;
    position: relative;
    margin-right: 0px;
}


/* Dark Theme zoom Modal  */


/* zoom Modal */

.modal-fullscreen {
    height: 75% !important;
    margin-top: 10px !important;
}

.modal-header button{
    cursor: pointer;
}

.zoom-tr th i{
    margin-right: 10px !important;
    font-size: 16px;
    font-weight: bold !important;
}

.zoom-list{
    margin-top: 10px;
}

.zoom-description{
    margin-top: 10px;
}

.single-client{
    display: flex;
    align-items: center;
    padding: 5px 0px 7px 20px;
    margin: 10px 0px !important;
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}

.single-client h6{
    margin: 0 !important;
}

.client-content{
    margin-left: 10px;
}

.join-btn{
  background-color: rgba(51, 51, 51, 0.05);
  border-radius: 8px;
  border-width: 0;
  color: #fff;
  background: green;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 5px 12px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.join-btn i{
    margin-right: 10px;
}
