.edit-icon {
  text-align: center;
  position: relative;
  bottom: 30px;
}

.edit-icon img {
  width: 100px;
}

.active {
  color: blue;
}
