.Toastify__toast-body {
  width: 100%;
}

.Toastify__close-button>svg {
  float: right;
  margin: 15px 10px;

}

