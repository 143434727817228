li.custom-next-btn.notch.page-item a{
    background-color: transparent !important;
    padding: 0 !important;
    border: none;
    color: black;
}
.button_name_fnext{
    color: black;
    font-size: 12px;
    font-weight: 500;
}
.footer_bnt_collie{
    background-color: rgb(219 219 215);
    color: white;
    border: 1px solid black;
    font-weight: 500;
    font-size: 12px;
    padding: 3px 14px;
    display: flex;
    justify-content: space-between;
    width: 260px;
    align-items: center;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: auto;
    cursor: pointer;
  }
  .custom-next-btn>.page-link:focus{
    box-shadow: none;
  }
  .huihiuh{
    width: 100%;
  }