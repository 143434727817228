.date-cellofmybox {
  position: relative;
}

.date-cellofmybox::after {
  content: '+';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: rgba(0, 0, 0, 0.26);
  cursor: pointer;
}

#mnbvcx>.modal-content {
  border-top-left-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
  background-color: rgb(244, 244, 232) !important;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 550px;

}

.line121 {
  border-top: 1px solid #dee2e6;
  margin-bottom: 8px;
}

.footer_bnt_coll {
  background-color: rgb(7, 48, 21);
  color: white;
  font-weight: 500;
  font-size: 12px;
  padding: 3px 14px;
  display: flex;
  justify-content: space-between;
  width: 260px;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: auto;
  cursor: pointer;
}

.button_name_f {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.columnthreee {
  background-color: transparent !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  height: 145px;
  margin: 0;
  flex-wrap: wrap;
  position: relative;
}

.nullimageclasse {
  width: 45px;
  height: 45px;
  margin-top: 2px;
}

.seleimgbox007 {
  border: 5px solid red;
  background: linear-gradient(90deg, rgba(189, 189, 232, 1) 0%, rgba(227, 232, 243, 1) 100%);
}

#mnbvcxxc>.modal-content {
  border-top-left-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  background-color: rgb(244, 244, 232) !important;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 550px;

}

.bodystylesa {
  border-top-left-radius: 55px;
  border-bottom-right-radius: 55px;
  background-color: rgb(244, 244, 232);
  overflow: hidden;
}

.bodystylesad {
  border-top-left-radius: 55px;
  border-bottom-right-radius: 55px;
  background-color: rgb(244, 244, 232);
  overflow: hidden;
}

.has-hdhdhimages {
  display: none;
}

.calh66 {
  margin-right: 199px;
}

.llpiop1 {
  margin-left: 176px;
}

.tabical {
  font-size: 12px;
  cursor: pointer;
  padding: 5px;
  flex: 1;
  text-align: center;
  font-weight: 600;
}

.tabical.active {
  background-color: #dfe0bc;
}

.tab-contentCalTab {
  height: 100%;
  margin-bottom: 10px;
  padding: 11px;
}

.tab_containerTab {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.iconad {
  margin-top: 0px;
  border-top: 1px solid #d1d1d1;
  text-align: center;
}

#mnbvcxLOP>.modal-content {
  border-top-left-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
  background-color: rgb(244, 244, 232) !important;
  border-top-left-radius: 55px;
  border-bottom-right-radius: 55px;
  height: 550px;

}

.button_name_fnextCAL {
  color: white;
  font-size: 12px;
  font-weight: 600;
}

.footer_bnt_collieCAL {
  background-color: rgb(7, 48, 21);
  color: white;
  font-weight: 500;
  font-size: 12px;
  padding: 3px 14px;
  display: flex;
  justify-content: space-between;
  width: 260px;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: auto;
  cursor: pointer;
}

.cvbnCAL {
  margin-left: 17px;
  margin-top: 20px;
  font-weight: 500;
}

.whitieboixxcTAB {
  margin: 20px 15px;
  background-color: white;
  height: 365px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgcls12 {
  height: auto;
  width: auto;
}

.calendar-wrapper .rbc-button-link {
  box-shadow: none;
}

.huhuh8u {
  font-size: 7px;
  justify-content: space-between;
  display: flex;
}


.box-item:hover .close-icdcal {
  display: block; 
}
.close-icdcal {
  position: absolute;
  right: 0px;
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  display: none;
}

.datepicdimns{
  width: 39px;
  max-height: 60px;
}

@media (max-width: 480px) {
  .datepicdimns{
    width: 25px;
  }
}

.modal_headerer{
  background: none !important;
  box-shadow: none !important;
  backdrop-filter: blur(0px) !important;
  border: none !important;
}
.close-iconnj{
 position: absolute;
 top: 1px;
 display: none;
 cursor: pointer;
    background-color: white;
    color: black;
    border: 1px solid #ccc;
    border-radius: 50%;
    right: 0px;
    
}
.columnthreee:hover .close-iconnj {
  display: block; /* Show close button when the parent container is hovered */
}