.c-toolbar {
    display: flex;
}
.c-date {
    padding: 15px 0;
    font-weight: bold;
}
.c-navigation {
    display: flex;
    margin-left: auto;
}

#authorize_button {
    width: auto;
    margin-bottom: 5px;
}



i.bi.bi-circle-fill._circleicon {
    font-size: 10px;
    color: rgb(206, 164, 73) !important;
}

#eventModal p {
    margin-bottom: 10px;
}

#eventModalLabel i {
    margin-right: 10px;
}

#eventModalLabel {
    margin-left: 10px;
}

#_bsmodal{
    display: flex;
    list-style: none;
}

._bsmodalli{
    padding: 0px 5px 0px 5px;
}

._bsmodalp{
    font-size: 15px;
    font-weight: 500;
}

._bsiconbi{
    font-size: 20px;
}