.styst {
    border: 1px solid #b2b0b0;
    background-color: #fcfaed;
}

label.lable_css {
    font-size: 13px;
    font-weight: 540;
    background: #d9d9d9;
    color: #111110;
    width: 100%;
    padding: 1px 5px;
    margin-right: 0px;
    margin-bottom: 0px;
    display: flex;
}

.lable_css_new {
    font-size: 14px;
    font-weight: 600;
    background: #d9d9d9;
    color: #111110;
    width: 100%;
    padding: 1px 5px;
    margin-right: 0px;
    margin-bottom: 0px;
    display: flex;
}

label.lable_css_ {
    font-size: 14px;
    font-weight: 600;
    background: #fcfaed;
    color: #111110;
    width: 100%;
    padding: 5px 5px;
    margin-right: 0px;
    margin-bottom: 0px;
}

.sybdbmn {
    background: #fcfaed;
}

.jadj {
    width: 100px;
    background-color: #fcfaed;
    margin-right: 0px;
}

.jkha {
    width: 100px;
    background-color: #d9d9d9;
    margin-right: 0px;
}

.shsh {
    width: 30px;
    background-color: #d3ebf3;
    margin-right: 0px;
    margin: 0px 1px 0px 0px;
}

.input_style_date {
    border: none;
    border-bottom: 1px solid #bbb;
    border-top: 1px solid #bbb;
    border-left: 1px solid;
    border-radius: 0px;
    padding-left: 0px;
    font-size: 14px;
    font-weight: 600;
}

.input_style_ {
    border: none;
    border-bottom: 1px solid #bbb;
    border-top: 1px solid #bbb;
    border-radius: 0px;
    padding-left: 10px;
    font-size: 13px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='2' y='13' fill='gray' font-size='13' font-family='arial'>$</text></svg>");
    background-position: center left;
    background-repeat: no-repeat;

}

.adksk {
    font-size: 14px;
    font-weight: 600;
    background: #fcfaed;
    width: 100%;
    padding: 1px 5px;
    margin-bottom: 0px;
    display: flex;
    margin: 0px;
}

.living_state {
    display: flex;
    margin: 0px 0px 0px 0px;
    border: 1px solid #111110;
}

.date_inpiut_ {
    margin-right: 5px;
}

.input_style {
    border: none;
    border-bottom: 1px solid #bbb;
    border-top: 1px solid #bbb;
    border-radius: 0px;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 600;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='2' y='13' fill='gray' font-size='13' font-family='arial'>$</text></svg>");
    background-position: center left;
    background-repeat: no-repeat;
}

.daildy_living {
    background-color: #e5c05d;
    color: #fcfaed;
    font-size: 15px;
    font-weight: 500;
    padding: 5px 10px;
    border: 1px solid #111110;
}

.save_data {
    background-color: #111110;
    color: #fcfaed;
    cursor: pointer;
    padding: 5px;
}

.dhhejk {
    border: 1px solid #b2b0b0;
    margin-top: 15px;
}

.rgshb {
    border: 1px solid #b2b0b0;
    margin-top: 38px;
}

.askwl {
    border: 1px solid #b2b0b0;

}

.main_adkj {
    margin-top: 2.3rem;
}

.title_cash {
    font-size: 15px;
    font-weight: 700;
    text-align: end;
}

.adsbkab {
    display: flex;
    justify-content: space-between;
}

/* month css------------------------------------------------------ */

.radiogroup_1 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.state {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 1e-5;
    pointer-events: none;
}

.label_m {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: #000000;
    font-size: 12px;
}


.label_m:hover {
    color: #111110 !important;
}

.text {
    margin-left: 22px;
    position: relative;
    z-index: 100;
    font-weight: 600;
    margin-bottom: 7px;
}

.text:hover {
    color: #fff;
}

.state:checked~.label_m .indicator::after {
    transform: scale3d(.975, .975, 1) translate3d(0, 10%, 0);
    opacity: 0;
}

.state:focus~.label_m .text {
    /* transform: translate3d(8px, 0, 0); */
    opacity: 1;
    color: #fff;
}

.label_m:hover .text {
    opacity: 1;
}