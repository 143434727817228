.side-border{
    border: 1px solid #5D6D7E;
    font-weight: bold;
    color: #000;
    background-color: #85929E ;
  }
  .side-border_2{
    border: 1px solid #5D6D7E;
    background-color: #4DD8D5  ;
  }
  .side-border_3{
    border: 1px solid #5D6D7E;
    background-color: #F09E30 ;
  }
  .side-border_4{
    border: 1px solid #5D6D7E;
    background-color: #42D86D  ;
  }
  .wholeborder{
    border: 1px solid ;
  }